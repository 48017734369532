<template>
  <div class="detail">
    <div class="nav">
      <div class="inner">
        <div class="left">
          <img src="../../assets/image/logo.png" alt="logo">
          <span>Adobe</span>
        </div>
        <div class="right">
          <div class="item"><router-link to="/index#index">首页</router-link></div>
          <div class="item"><router-link to="/index#list">商品列表</router-link></div>
          <div class="item"><router-link to="/index#reward">免费赠品</router-link></div>
          <div class="item"><router-link to="/index#msg">客户评价</router-link></div>
          <div class="item"><router-link to="/index#problem">常见问题</router-link></div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="part1">
        <div class="img">
          <img :src="goodInfo.img">
          <div class="goodsName">{{ goodInfo.name }}</div>
        </div>
        <div class="goodsIntro">享活动优惠，物超所值！完整版{{ goodInfo.name }}，全网底价，永久使用</div>
        <div class="line">
          <div class="left">优惠价格</div>
          <div class="right" style="color: red;">￥{{ goodInfo.price }}永久使用</div>
        </div>
        <div class="line">
          <div class="left">兼容系统</div>
          <div class="right">完美兼容Windows和Mac系统</div>
        </div>
        <div class="line">
          <div class="left">服务内容</div>
          <div class="right"><span style="color: red;">包安装成功，不成功立马退款，终身包升级</span></div>
        </div>
        <div class="line">
          <div class="left">累计下载</div>
          <div class="right" style="color: red;">5029</div>
        </div>
        <!-- <div class="line">
          <div class="left"><span style="color: red;">选择版本</span></div>
          <div class="right">
            <el-select v-model="value" placeholder="请选择" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div> -->
        <div class="line">
          <div class="left">支付方式</div>
          <div class="right">
            <div class="pay">
              <img src="../../assets/image/weixin.png" :class="payType == 1 ? 'active' : ''" @click="changePayType(1)">
              <img src="../../assets/image/zhifubao.png" :class="payType == 2 ? 'active' : ''" @click="changePayType(2)">
            </div>
          </div>
        </div>
        <div class="btn" @click="toPay">立即支付</div>
      </div>
      <div class="part2">
        <div class="title">商品详情：</div>
        <img :src="item" v-for="item in imgs" :key="item">
      </div>
    </div>
    <div class="float" :style="{ right: right + 'px' }">
      <div class="img" @click="hover">
        <img src="../../assets/image/QQ.png">
      </div>
      <div class="inner">
        <img src="../../assets/image/二维码.png" alt="">
        <p>扫码联系客服QQ</p>
        <p>7*24小时在线</p>
        <p>123456789</p>
      </div>
    </div>
  </div>
</template>
  
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

.detail {
  width: 100%;
  background: linear-gradient(to right, lightblue, rgb(255, 218, 224), rgb(205, 233, 243));
  padding: 80px 0 50px;
}

.nav {
  width: 100vw;
  height: 80px;
  box-shadow: 0 5px 10px 1px #ddd;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;

  .inner {
    width: 1116px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item {
        margin: 0 20px;
        cursor: pointer;

        a {
          color: #001E36;
          text-decoration: none;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

  }
}

.body {
  width: 90%;
  margin: 50px auto;
  overflow: hidden;
  padding: 50px 200px;
  background-color: #fff;
  border-radius: 10px;

  .part1 {
    .img {
      display: flex;
      align-items: center;


      img {
        width: 50px;
        height: 50px;
      }

      .goodsName {
        margin-left: 20px;
        font-size: 30px;
        font-weight: bold;
      }
    }



    .goodsIntro {
      margin-top: 20px;
      font-size: 20px;
    }

    .line {
      display: flex;
      align-items: center;
      margin: 20px 0;

      .left {
        font-weight: bold;
        font-size: 14px;
        display: block;
        width: 75px;
        float: left
      }

      .right {
        margin-left: 10px;

        .pay {
          display: flex;
          align-items: center;

          img {
            width: 142px;
            height: 42px;
            border-radius: 4px;
            margin-right: 10px;

            &.active {
              border: 1px solid rgb(67, 19, 165);
            }
          }
        }
      }
    }

    .btn {
      display: block;
      width: 192px;
      height: 45px;
      background: orangered;
      border-radius: 8px;
      line-height: 45px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      text-decoration: none;
      margin: 20px 0;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .part2 {
    width: 100%;
    margin: 50px auto;

    .title {
      background-color: #f4f4f4;
      height: 50px;
      line-height: 50px;
      padding-left: 50px;
      font-size: 14px;
      font-weight: bold;
    }

    img {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }


}

.float {
  position: fixed;
  bottom: 120px;
  display: flex;
  transition: .5s all;

  .img {
    width: 60px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .inner {
    background-color: #0590DF;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>img {
      width: 80px;
      height: 80px;
    }

    p {
      margin: 5px 0;
    }
  }
}
</style>
  
<script>

export default {
  data() {
    return {
      id: '',
      right: -144,
      payType: 1,
      goodInfo: {},
      imgs: [
        require('../../assets/image/d1.jpg'),
        require('../../assets/image/d2.jpg'),
        require('../../assets/image/d3.jpg'),
        require('../../assets/image/d4.jpg'),
        require('../../assets/image/d5.jpg'),
        require('../../assets/image/d6.jpg'),
        require('../../assets/image/d7.jpg'),
        require('../../assets/image/d8.jpg'),
        require('../../assets/image/d9.jpg'),
        require('../../assets/image/d10.jpg'),
        require('../../assets/image/d11.jpg'),
        require('../../assets/image/d12.jpg'),
        require('../../assets/image/d13.jpg'),
        require('../../assets/image/d14.jpg'),
        require('../../assets/image/d15.jpg'),
        require('../../assets/image/d16.jpg'),
        require('../../assets/image/d17.jpg'),
        require('../../assets/image/d18.jpg'),
        require('../../assets/image/d19.jpg'),
      ],
      options: [{
        value: 1,
        label: 'window 全家桶2024'
      }, {
        value: 2,
        label: 'mac 全家桶2024'
      }],
      value: 1
    }
  },
  methods: {
    hover() {
      if (this.right == -144) {
        this.right = 0
      } else {
        this.right = -144
      }
    },
    changePayType(n) {
      this.payType = n
    },
    toPay() {
      // console.log(this.payType,11111);
      this.$router.push('/pay?payType=' + this.payType);
      localStorage.setItem('payOption', JSON.stringify(this.goodInfo));
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    const goodsList = [{
      id: 1,
      name: 'Adobe 软件全套',
      intro: '正版激活，17款软件，完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/adobe.jpg')
    }, {
      id: 2,
      name: 'Adobe Photoshop',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 3,
      name: 'Adobe Premiere',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 4,
      name: 'Adobe After Effects',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 5,
      name: 'Adobe Dreamweaver',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 6,
      name: 'Adobe Illustrator',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 7,
      name: 'Adobe Animate',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 8,
      name: 'Adobe Audition',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 9,
      name: 'Adobe InDesign',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 1699,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 10,
      name: 'Adobe Lightroom',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 11,
      name: 'Adobe PDF',
      intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 13,
      name: 'Photoshop 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 14,
      name: 'Photoshop 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 15,
      name: 'Photoshop 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 16,
      name: 'Photoshop 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 17,
      name: 'Photoshop 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/ps1.png')
    }, {
      id: 18,
      name: 'Photoshop 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps2.png')
    }, {
      id: 19,
      name: 'Photoshop 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps2.png')
    }, {
      id: 20,
      name: 'Photoshop CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ps3.png')
    }, {
      id: 21,
      name: 'Adobe 全家桶',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 1699,
      img: require('../../assets/image/adobe.jpg')
    }, {
      id: 22,
      name: 'Adobe Premiere 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 23,
      name: 'Adobe Premiere 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 24,
      name: 'Adobe Premiere 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 25,
      name: 'Adobe Premiere 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 26,
      name: 'Adobe Premiere 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/pr1.png')
    }, {
      id: 27,
      name: 'Adobe Premiere 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr2.png')
    }, {
      id: 28,
      name: 'Adobe Premiere 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr2.png')
    }, {
      id: 29,
      name: 'Adobe Premiere CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pr2.png')
    }, {
      id: 30,
      name: 'Adobe After Effects 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 31,
      name: 'Adobe After Effects 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 32,
      name: 'Adobe After Effects 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 33,
      name: 'Adobe After Effects 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 34,
      name: 'Adobe After Effects 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/ae1.png')
    }, {
      id: 35,
      name: 'Adobe After Effects 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae2.png')
    }, {
      id: 36,
      name: 'Adobe After Effects 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae2.png')
    }, {
      id: 37,
      name: 'Adobe After Effects CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ae2.png')
    }, {
      id: 38,
      name: 'Adobe Dreamweaver 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 39,
      name: 'Adobe Dreamweaver 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 40,
      name: 'Adobe Dreamweaver 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 41,
      name: 'Adobe Dreamweaver 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 42,
      name: 'Adobe Dreamweaver 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/dw1.png')
    }, {
      id: 43,
      name: 'Adobe Dreamweaver 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw2.png')
    }, {
      id: 44,
      name: 'Adobe Dreamweaver 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw2.png')
    }, {
      id: 45,
      name: 'Adobe Dreamweaver CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/dw2.png')
    }, {
      id: 46,
      name: 'Adobe Illustrator 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 47,
      name: 'Adobe Illustrator 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 48,
      name: 'Adobe Illustrator 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 49,
      name: 'Adobe Illustrator 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 50,
      name: 'Adobe Illustrator 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/ai1.png')
    }, {
      id: 51,
      name: 'Adobe Illustrator 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai2.png')
    }, {
      id: 52,
      name: 'Adobe Illustrator 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai2.png')
    }, {
      id: 53,
      name: 'Adobe Illustrator CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/ai3.png')
    }, {
      id: 54,
      name: 'Adobe Animate 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 55,
      name: 'Adobe Animate 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 56,
      name: 'Adobe Animate 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 57,
      name: 'Adobe Animate 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 58,
      name: 'Adobe Animate 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/an1.png')
    }, {
      id: 59,
      name: 'Adobe Animate 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an2.png')
    }, {
      id: 60,
      name: 'Adobe Animate 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an2.png')
    }, {
      id: 61,
      name: 'Adobe Animate CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/an2.png')
    }, {
      id: 62,
      name: 'Adobe Audition 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 63,
      name: 'Adobe Audition 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 64,
      name: 'Adobe Audition 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 65,
      name: 'Adobe Audition 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 66,
      name: 'Adobe Audition 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/au1.png')
    }, {
      id: 67,
      name: 'Adobe Audition 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au2.png')
    }, {
      id: 68,
      name: 'Adobe Audition 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au2.png')
    }, {
      id: 69,
      name: 'Adobe Audition CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/au2.png')
    }, {
      id: 70,
      name: 'Adobe InDesign 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/id1.png')
    }, {
      id: 71,
      name: 'Adobe InDesign 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id1.png')
    }, {
      id: 72,
      name: 'Adobe InDesign 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id1.png')
    }, {
      id: 73,
      name: 'Adobe InDesign 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id1.png')
    }, {
      id: 74,
      name: 'Adobe InDesign 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/id1.png')
    }, {
      id: 75,
      name: 'Adobe InDesign 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id2.png')
    }, {
      id: 76,
      name: 'Adobe InDesign 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id2.png')
    }, {
      id: 77,
      name: 'Adobe InDesign CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/id2.png')
    }, {
      id: 78,
      name: 'Adobe Lightroom 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 79,
      name: 'Adobe Lightroom 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 80,
      name: 'Adobe Lightroom 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 81,
      name: 'Adobe Lightroom 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 82,
      name: 'Adobe Lightroom 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/lr1.png')
    }, {
      id: 83,
      name: 'Adobe Lightroom 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr2.png')
    }, {
      id: 84,
      name: 'Adobe Lightroom 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr2.png')
    }, {
      id: 85,
      name: 'Adobe Lightroom CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/lr2.png')
    }, {
      id: 86,
      name: 'Adobe PDF 2024',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 248,
      otPrice: 1199,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 87,
      name: 'Adobe PDF 2023',
      intro: '最新版本首发,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 88,
      name: 'Adobe PDF 2022',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 89,
      name: 'Adobe PDF 2021',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 90,
      name: 'Adobe PDF 2020',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 68,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 91,
      name: 'Adobe PDF 2019',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 92,
      name: 'Adobe PDF 2018',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 93,
      name: 'Adobe PDF CS6',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 999,
      img: require('../../assets/image/picture/pdf1.png')
    }, {
      id: 21,
      name: 'Adobe 全家桶',
      intro: '自动识别电脑配置,完美支持window和mac系统',
      price: 78,
      otPrice: 1699,
      img: require('../../assets/image/adobe.jpg')
    }]

    this.id = this.$route.params.id
    goodsList.forEach(item => {
      if (item.id == this.id) {
        this.goodInfo = item;
        return
      }
    })
  }
}
</script>
  
  